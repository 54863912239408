import { defineStore } from 'pinia'

export const useUserMsgStore = defineStore('userMsgs', {
  state: () => ({
    sysMsgs: 0,
    likesMsgs: 0,
    cmtMsgs: 0,
  }),
  // 也可以这样定义状态
  // state: () => ({ count: 0 })
  getters: {
    getTotal: (state) => {
      return state.cmtMsgs + state.sysMsgs + state.likesMsgs;
    },
  },
  actions: {
    uptSys(num) {
      this.sysMsgs = num
    },
    uptLikes(num) {
      this.likesMsgs = num
    },
    uptCmt(num) {
      this.cmtMsgs = num
    }
  },
})